export default {
  data() {
    return {
      active: 0,
      icon: {
        index: require("@/assets/btn_home01.png"),
        indexSel: require("@/assets/btn_home02.png"),
        future: require("@/assets/btn_df01.png"),
        futureSel: require("@/assets/btn_df02.png"),
        equity: require("@/assets/btn_ds01.png"),
        equitySel: require("@/assets/btn_ds02.png"),
        my: require("@/assets/btn_center01.png"),
        mySel: require("@/assets/btn_center02.png")
      }
    };
  },
  watch: {
    // 监听路由对象中的变化
    '$route': {
      handler: function (to) {
        this.active = to.meta.tabbar;
      },
      // 如果需要在路由变化时立即响应，可以设置immediate为true
      immediate: true
    }
  }
};