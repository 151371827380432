import "core-js/modules/es.array.push.js";
import Tabbar from "@/components/tabbar";
import { listNav } from "@/api";
import { getCookie } from "@/utils/auth";
export default {
  components: {
    Tabbar
  },
  data() {
    return {
      listData: []
    };
  },
  mounted() {
    listNav().then(res => {
      this.listData = res.data;
    });
  },
  methods: {
    goFutureList(value) {
      //   if (!getCookie("token")) {
      //     this.$router.push({ path: "/login", query: { redirect: "/future-list" } })
      //     return
      //   }
      this.$router.push(`/future-list?id=${value.id}&title=${value.name}`);
    }
  }
};